import React from 'react'
import { Box, Container, InputAdornment } from '@mui/material'
import { Lock } from '@mui/icons-material'
import { Formik, Form } from 'formik'
import { object, string } from 'yup'
import { useQueryParam, StringParam } from 'use-query-params'
import { Auth } from '@aws-amplify/auth'

import { useAccount } from 'account/context/AccountContext'

import AuthPageLayout from 'account/components/organisms/global/AuthPageLayout'
import Flex from '_core/components/atoms/layout/Flex'
import Section from '_core/components/atoms/layout/Section'
import FormikTextField from '_core/components/atoms/inputs/formik/FormikTextField'
import Button from '_core/components/atoms/buttons/Button'
import Alert from '_core/components/atoms/feedback/Alert'
import PasswordHelperText from '_core/components/atoms/inputs/PasswordHelperText'
import { useLoading } from '_core/context/LoadingContext'
import { useMessage } from '_core/context/MessageContext'
import AWSConfig from 'account/constants/aws-config'

Auth.configure(AWSConfig)

type formValues = {
  email: string
  code: string
  password: string
  confirmPassword: string
}

const ResetPasswordVerification = () => {
  const { dispatch: loadingDispatch } = useLoading()
  const { dispatch: messageDispatch } = useMessage()

  const [email, setEmail] = useQueryParam('email', StringParam)
  const [code, setCode] = useQueryParam('code', StringParam)

  const initialValues = {
    code: code ? code : '',
    email: email ? email : '',
    password: '',
    confirmPassword: '',
  }
  const validationSchema = object().shape({
    email: string()
      .email('Please supply a valid email address')
      .required('Email address is Required'),
    password: string()
      .required('Please Enter your password')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/,
        'Password is not strong enough'
      ),
    confirmPassword: string().required('Re-enter your password'),
  })

  const handleSubmit = async (values: formValues) => {
    loadingDispatch({ type: 'START_LOADING' })
    let statusObj
    try {
      await Auth.forgotPasswordSubmit(
        values.email,
        values.code,
        values.password
      )
      messageDispatch({
        type: 'SET_MESSAGE',
        payload: {
          message: 'Your password has been reset.',
          status: 'success',
        },
      })
      loadingDispatch({ type: 'STOP_LOADING' })
      return statusObj
    } catch (err) {
      messageDispatch({
        type: 'SET_MESSAGE',
        payload: {
          message: err.message,
          status: 'error',
        },
      })
      loadingDispatch({ type: 'STOP_LOADING' })
      return statusObj
    }
  }

  return (
    <>
      <AuthPageLayout>
        <Flex flexDirection="column" fullWidth style={{ minHeight: '100vh' }}>
          <Flex center textAlign="center" flexGrow="1">
            <Container maxWidth="sm">
              <Box py="12">
                <Box component="h1" typography="h2" mb={3}>
                  Set your new password
                </Box>

                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  validate={(values) => {
                    const errors = {}
                    if (values.password !== values.confirmPassword) {
                      errors.confirmPassword = 'Passwords do not match'
                    }
                    return errors
                  }}
                  onSubmit={(values) => {
                    handleSubmit(values)
                  }}
                >
                  <Form>
                    <FormikTextField name="code" type="hidden" />
                    <FormikTextField name="email" type="hidden" />
                    <FormikTextField
                      name="password"
                      label="Password"
                      placeholder="Password"
                      type="password"
                      withAdornment
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Lock sx={{ fontSize: '1.8rem' }} />
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                      caps
                      required
                      sx={{ mb: 2 }}
                    />
                    <FormikTextField
                      name="confirmPassword"
                      label="Confirm Password"
                      placeholder="Confirm Password"
                      type="password"
                      withAdornment
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Lock sx={{ fontSize: '1.8rem' }} />
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                      caps
                      required
                      sx={{ mb: 2 }}
                    />
                    <PasswordHelperText />
                    <Flex center fullWidth justifyContent="space-between">
                      <Button
                        variant="contained"
                        color="primary"
                        sizeVariant="xl"
                        type="submit"
                      >
                        Reset
                      </Button>
                    </Flex>
                  </Form>
                </Formik>
              </Box>
            </Container>
          </Flex>
        </Flex>
      </AuthPageLayout>
    </>
  )
}

export default ResetPasswordVerification
